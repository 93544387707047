<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <v-data-table
            :headers="headers"
            :items="orders"
            :items-per-page="10"
            class="elevation-1">
          <template v-slot:item.product_title="{ item }">
            <v-btn text :to="'products/' + item.product_id">
              {{ item.product_title }}
            </v-btn>
          </template>
          <template v-slot:item.status="{ item }">
            <v-btn rounded style="box-shadow: none" v-if="item.status == 'rejected'" color="red">
              {{ item.status }}
            </v-btn>
            <v-btn rounded style="box-shadow: none" v-else-if="item.status == 'pending'" color="orange">
              {{ item.status }}
            </v-btn>
            <v-btn rounded style="box-shadow: none" v-else-if="item.status == 'sending'" color="blue">
              {{ item.status }}
            </v-btn>
            <v-btn rounded style="box-shadow: none" v-else-if="item.status == 'delivered'" color="success">
              {{ item.status }}
            </v-btn>
            <v-btn rounded style="box-shadow: none" v-else>
              {{ item.status }}
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Orders",
  data() {
    return {
      headers: [
        {
          text: 'Product',
          align: 'start',
          sortable: false,
          value: 'product_title',
        },
        { text: 'Receiver Name', value: 'receiver_name' },
        { text: 'Receiver Number', value: 'receiver_number' },
        { text: 'Address', value: 'address' },
        { text: 'Zipcode', value: 'zip_code' },
        { text: 'Factor Number', value: 'factor_number' },
        { text: 'estimated_date', value: 'estimated_date' },
        { text: 'status', value: 'status' },
      ],
      orders: [],
    }
  },
  mounted() {
    window.axios.get('api/my-orders').then((res) => {
      this.orders = res.data.data
    })
  }
}
</script>

<style scoped>

</style>